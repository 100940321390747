import React, { useState, useEffect, useRef } from 'react';
import { Box,ListItemButton,List,ListItem,ListItemText,Divider,Dialog,DialogContentText,DialogTitle,DialogContent,DialogActions,Button,ButtonGroup,Typography,CircularProgress,Link } from '@mui/material';
import './Studio.css';
import { useTheme } from './ThemeContext';
import { useLoading } from './LoadingContext';
import { useLanguage } from './LanguageContext';
import axios from 'axios';
import CustomTextField from './CustomTextField';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSweetAlert from './SweetAlert';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const CustumVideoEditer = () => {
    const canvasRef = useRef(null);
    const { showAlert } = useSweetAlert();
    const { theme } = useTheme();
    const [showHowToEditerDialog, setShowHowToEditerDialog] = useState(false);
    const isLight = theme === 'light';
    const cnavasBackgroundColor = isLight ? '#eaeaea' : '#0f0b0b';
    const videoListBackgroundColor = isLight ? '#f1f1f1' : '#141010';
    const { showLoading, hideLoading } = useLoading();
    const { language } = useLanguage();
    const [loadingViewBox, setLoadingViewBox] = useState(false);
    const [resultViewBox, setResultViewBox] = useState('');
    const [editerBoxData, setEditerBoxData] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [openDeleteVideoBox, setOpenDeleteVideoBox] = useState(false);
    const [openProjectListDialog, setOpenProjectListDialog] = useState(false);
    const [loadingEditerBox, setLoadingEditerBox] = useState(false);
    const [loadingDeleteVideoBox, setLoadingDeleteVideoBox] = useState(false);
    const [loadingShare, setLoadingShare] = useState(false);

    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoID, setSelectedVideoID] = useState(0);
    const [selectedTime, setSelectedTime] = useState(0);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [genProcessType, setGenProcessType] = useState('');
    const [cutRanges, setCutRanges] = useState({});
    const [settingStart, setSettingStart] = useState(true); // To track start or end time
    //const [IsLogin, setIsLogin] = useState(false);
    const [showHowToStickerDialog, setShowHowToStickerDialog] = useState(false);
    const storedValue = localStorage.getItem('isMobile');
    const isMobileBoolean = storedValue === 'true'; // Convert the string back to a boolean
    const [IsLogin, setIsLogin] = useState(() => {
        // Attempt to load the user from localStorage
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });

      const generateFileName = () => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit random number
        return `samrt-loader.com_${randomNumber}.mp4`;
      };

      const shareVideo = async (videoUrl) => {
        setLoadingShare(true);
        try {
          const response = await fetch(videoUrl);
          const blob = await response.blob();
          const file = new File([blob], generateFileName(), { type: 'video/mp4' });
    
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Check out this video',
              text: 'Here is a video I downloaded!',
            });
          } else {
            console.error('Sharing not supported on this browser');
          }
        } catch (error) {
          console.error('Error sharing video:', error);
        }
        setLoadingShare(false);
      };

      const handleOpenHowToStickerDialog = () => {
        setShowHowToStickerDialog(true);
      }
  
      const handleCloseHowToStickerDialog = () => {
        setShowHowToStickerDialog(false);
      }

      const handleCloseHowToEditerDialog = () => {
        setShowHowToEditerDialog(false);
      };

      const handleOpenHowToEditerDialog = () => {
        setShowHowToEditerDialog(true);
      };

      const [isProjectId, setIsProjectId] = useState(() => {
        // Attempt to load the user from localStorage
        const projectID = localStorage.getItem('project_id');
        if (projectID !== "0") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });

    const handleOpenEditerBox = async() => {
        try{
            setOpenProjectListDialog(true);
            setLoadingEditerBox(true);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/list/projects_video_editer`, {
              method: 'GET',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              }
            });
            const data = await response.json();
            if(data.success){
                setEditerBoxData(data.projects_video_editer);
            } else {
                handleCloseProjectList();
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                      'An error occurred while getting your account data, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  localStorage.setItem('authToken', null);
                  localStorage.setItem('isLogin', 'false');
                  localStorage.setItem('userid', null);
                  localStorage.setItem('username', null);
                  localStorage.setItem('userlevelid', null);  
            }
        } catch(error) {
            handleCloseProjectList();
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                  'An error occurred while getting your account data, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              localStorage.setItem('authToken', null);
              localStorage.setItem('isLogin', 'false');
              localStorage.setItem('userid', null);
              localStorage.setItem('username', null);
              localStorage.setItem('userlevelid', null);  
        } finally {
            setLoadingEditerBox(false);
        }
    };

    const handleChoose = async(projectID) => {
        localStorage.setItem('project_id',projectID)
        setIsProjectId(true);
        fetchVideos();   
    }

    const handleCloseDeleteVideoBox = () => {
      setOpenDeleteVideoBox(false);
    }

    const handelDeleteVideoList = async() => {
      setOpenDeleteVideoBox(true);
    }

    const fetchVideos = async () => {
        try {
            const token = localStorage.getItem('authToken');        
            const response = await axios.get(
                `https://samrt-loader.com/kydwon/api/list/process_video_editer?showmaster=projects_video_editer&fk_project_id=${localStorage.getItem('project_id')}`,
                {
                    headers: {
                        'X-Authorization': `Bearer ${token}` // Add JWT token to the request headers
                    }
                }
            );
            if (response.data.success) {
                const videoData = response.data.process_video_editer;
                setVideos(videoData);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            hideLoading();
            handleCloseProjectList();
        }
    };

    const handleDeletVideoBox = async () => {
      try{
        handleCloseDeleteVideoBox();
        showLoading();
        var done = false;
        const formData = new FormData();
        formData.append('key_m[]', selectedVideoID);
        const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/process_video_editer`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data = await response.json();
        if(data.success){
            done = true;
        } else {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        }
    } catch(error) {
        showAlert({
            text: language === 'ar' ? 
              'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
              'An error occurred while deleting, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
          });
    } finally {
        hideLoading();
        if(done){
            showAlert({
                text: language === 'ar' ? 
                  'تمت الحذف بنجاح' : 
                  'Deleting successful',
                icon: 'success',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              setSelectedVideo(null);
              setSelectedFrame(null);
              fetchVideos();
        }
    }
    }

    useEffect(() => {
        if(IsLogin){
            if(isProjectId){
                showLoading();
                fetchVideos();
            } else {
                handleOpenEditerBox();
            }
        }else {
            setOpenLoginDialog(true);
        }
    }, []);

    const handleClose = () => {
        setOpenLoginDialog(false);
    }

    const handleCloseProjectList = () => {
        setOpenProjectListDialog(false);
    }

    const handleSelectVideo = (video) => {
        setSelectedVideo(video);
        setSelectedVideoID(video.process_id);
        setSelectedFrame(null);
    };

    const handleSelectFrame = (frame, index) => {
        setSelectedFrame(frame);
        handleSelectTime(index); // Pass the index to calculate time
    };

    const handleGenerateAddSoundProcess = async() => {

    };

    const handleCutRangeChange = (videoId, field, value) => {
      setCutRanges((prev) => {
          const currentRange = prev[videoId] || {};
  
          if (field === 'startTime') {
              // Ensure startTime is less than endTime (if already set)
              if (currentRange.endTime && value >= currentRange.endTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت البداية أقل من وقت النهاية' : 
                        'Start time must be less than the end time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          } else if (field === 'endTime') {
              // Ensure endTime is greater than startTime (if already set)
              if (currentRange.startTime && value <= currentRange.startTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت النهاية أكبر من وقت البداية' : 
                        'End time must be greater than the start time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          }
  
          // Update the cut range if validation passes
          return {
              ...prev,
              [videoId]: {
                  ...currentRange,
                  [field]: value
              }
          };
      });
  };

    const handleGenerateProcess = async (processType) => {
      try {
          if (processType === 'cut_video') {
            if(isAllFramesSelected()){
              const selectedCutRanges = Object.entries(cutRanges).map(([videoId, cutRange]) => ({
                videoId,
                startTime: cutRange.startTime,
                endTime: cutRange.endTime,
            }));
            showLoading();
            const response = await axios.post('https://samrt-loader.com/process_video_editer', {
              videos: selectedCutRanges,
            });
            if (response.data.success) {
                checkStatus(response.data.task_id,processType);
            } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
            }
          } else {
            showAlert({
              text: language === 'ar' ? 'الرجاء تحديد إطار الوقت لجميع الفيديوهات في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
          } else {
            const video = cutRanges[selectedVideoID];  // Assuming single video for gif conversion
            if(video.startTime > 0 && video.endTime > 0 && video.startTime !== '' && video.startTime !== null && !isNaN(video.startTime) && video.startTime !== undefined && video.endTime !== '' && video.endTime !== null && !isNaN(video.endTime) && video.endTime !== undefined) {
              const response = await axios.post('https://samrt-loader.com/process_video_editer', {
                process_type: 'video_to_gif',
                video_id: selectedVideoID,  // Replace with actual video ID
                startTime: video.startTime,  // Start time in seconds
                endTime: video.endTime  // End time in seconds
              });
              if (response.data.success) {
                checkStatus(response.data.task_id,processType);
              } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
               }
            } else {
              showAlert({
                text: language === 'ar' ? 'الرجاء تحديد إطار الوقت للفيديو المحدد في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          }
      } catch (error) {
          console.error('Error sending data to backend:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ الرجاء التكأد من تحديد الوقت لكل فيديو وإعادة المحاولة أو المحاولة لاحقا' : 'An error, Please specify the time frame for all videos in the sidebar or try again later!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
      } finally {
          hideLoading();
      }
    };

    const isAllFramesSelected = () => {
      let allFramesSelected = true;
    
      videos.forEach((video) => {
        const videoId = video.process_id; // Assuming each video object has an 'id'
        const cutRange = cutRanges[videoId]; // Get the cut range for this video
    
        if (!cutRange || !Object.values(cutRange).every(
          (value) => value !== '' && value !== null && !isNaN(value) && value > 0 && value !== undefined
        )) {
          allFramesSelected = false;
        }
      });
    
      return allFramesSelected;
    };

    const checkStatus = async (taskId,type) => {
        setLoadingViewBox(true);
        setOpenViewModal(true);
        const statusResponse = await fetch(`https://samrt-loader.com/task_status_editer/${taskId}`);
        const statusData = await statusResponse.json();
    
        if (statusData.status === 'PENDING') {
          setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
        } else if (statusData.status === 'SUCCESS') {
          const url = statusData.result;
          setResultViewBox(url);
          setGenProcessType(type);
          setLoadingViewBox(false);
          //console.error('success path', url);
        } else {
          console.error('Error processing video:', statusData.status);
          setOpenViewModal(false);
          showAlert({
            text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      };

    const handleSelectTime = (frameIndex) => {
        const currentTime = frameIndex + 1; // Assuming frame 1 corresponds to 1 second, frame 2 to 2 seconds, etc.
        setSelectedTime(currentTime);
        const field = settingStart ? 'startTime' : 'endTime';
        handleCutRangeChange(selectedVideoID, field, currentTime);
        setSettingStart(!settingStart); // Toggle between start and end time
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    }

    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    return (
        <div>
        <SEO 
        title={language === 'ar' ? keywordsData.title_editer_ar : keywordsData.title_editer_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
 {IsLogin ? (
            <div className="studio-container">
            <div className="video-list" style={{ backgroundColor: videoListBackgroundColor }}>
                {videos.map((video, index) => (
                    <div key={index} className="video-thumbnail" onClick={() => handleSelectVideo(video)}>
                        <img src={video.process_video_thumbnail_url} alt={video.process_orgenal_video_file.name} />
                        <p>{video.process_orgenal_video_file.name}</p>
                    </div>
                ))}
            </div>

            <div className="editor-area">
                <div className="action-bar">
                    {selectedVideoID > 0 && (
                        <div className="cut-inputs">
                            <CustomTextField
                                label={language === 'ar' ? 'وقت البداية' : 'Start Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.startTime || ''}
                                type="number"
                                name="startTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'startTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                            <CustomTextField
                                label={language === 'ar' ? 'وقت النهاية' : 'End Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.endTime || ''}
                                type="number"
                                name="endTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'endTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                        </div>
                    )}
                    <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                        <Button aria-label="choos project" variant="contained" color="inherit" onClick={handleOpenEditerBox}>
                            {language === 'ar' ? 'قائمة المواضيع' : 'Projects list'}
                        </Button>
                        <Button aria-label="generate" variant="contained" color="success" onClick={() => handleGenerateProcess('cut_video')}>
                            {language === 'ar' ? 'إنشاء' : 'Generate'}
                        </Button>
                    </ButtonGroup>
                </div>

                <div className="frame-bar">
                    {selectedVideo && selectedVideo.process_video_frames.split(', ').map((frame, index) => (
                        <div key={index} className="frame" onClick={() => handleSelectFrame(frame,index)}>
                            <img src={frame} alt={`Sec ${index + 1}`} />
                            <p>{`Sec ${index + 1}`}</p>
                        </div>
                    ))}
                </div>
                {selectedVideo && (
                        <Box my={1} mt={1} width={'100%'} alignItems={'center'} textAlign={'center'}>
                            <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                                <Button disabled={true} aria-label={"Add sound"} variant="contained" color="inherit" onClick={handleGenerateAddSoundProcess}>
                                    {language === 'ar' ? 'إضافة صوت (soon)' : 'Add sound (soon)'}
                                </Button>
                                <Button aria-label={"video to gif"} variant="contained" color="inherit" onClick={() => handleGenerateProcess('video_to_gif')}>
                                    {language === 'ar' ? 'إنشاء ستيكر' : 'GIF/Sticker'}
                                </Button>
                                <Button aria-label={"delete from list"} variant="contained" color="error" onClick={handelDeleteVideoList}>
                                    {language === 'ar' ? 'حذف من القائمة' : 'Delete from list'}
                                </Button>
                            </ButtonGroup>
                        </Box>
                )}
                <div className="canvas-preview" style={{ backgroundColor: cnavasBackgroundColor }}>
                    {selectedFrame ? (
                        <img src={selectedFrame} alt="Selected Frame" />
                    ) : (
                        <p>{language === 'ar' ? 'إختر إطار لبدء التحديد' : 'Select a frame to preview'}</p>
                    )}
                </div>
            </div>
            <Dialog open={openViewModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض' : 'View'}</DialogTitle>
        <DialogContent textAlign={'center'}>
        {loadingViewBox ? (
                    <span>
                        <CircularProgress size={24} /> {language === 'ar' ? 'جار الإنشاء (قد يستغرق عدة دقائق) ...' : 'Prossesing (it may take awhile) ...'}
                    </span>
                ) : (
                  <span>
                  {/* Check if the resultViewBox ends with '.mp4' or '.gif' */}
                  {resultViewBox.endsWith('.mp4') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <ReactPlayer url={resultViewBox} controls width="100%" style={{ maxHeight: '380px' }} />
                    </Box>
                  ) : resultViewBox.endsWith('.gif') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <img src={resultViewBox} width="300px" height={'100%'} style={{ maxHeight: '300px' }} />
                    </Box>
                  ) : (
                    <span>
                      {language === 'ar' ? 'الرجاء المحاولة لاحقاً' : 'Please try again later'}
                    </span>
                  )}
                </span>
                )}
        </DialogContent>
        {!loadingViewBox && (
        <DialogActions>
        <Button aria-label={"close"} disabled={loadingViewBox} onClick={handleCloseViewModal} color="secondary">
          {language === 'ar' ? 'إغلاق' : 'Cancel'}
        </Button>

        {isMobileBoolean ? (
          <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
              <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={resultViewBox} download>
                <FontAwesomeIcon icon="fa-download" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
              </Button> 
              <Button disabled={loadingShare} aria-label={"download_share_edit_video"} color="info" onClick={() => shareVideo(resultViewBox)}>
                    {loadingShare ? (
                      <span>
                        <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Donloading ...'}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon icon="fa-share" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل و مشاركة' : 'Download & Share'}
                      </span>
                    )}
              </Button> 
          </ButtonGroup>
        ) : (
          <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={resultViewBox} download>
            <FontAwesomeIcon icon="fa-download" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
          </Button> 
        )}
        </DialogActions>
        )}
      </Dialog>

      <Dialog open={openProjectListDialog} onClose={handleCloseProjectList} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'قائمة المحرر' : 'Editer list'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'إختيار موضوع جديد للمحرر' : 'Choose your projects'}
          </DialogContentText>
          {loadingEditerBox ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
    ) : editerBoxData.length > 0 ? (
        <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <List>
          {editerBoxData.map((project) => (
            <React.Fragment key={project.project_id}>
              <ListItem>
                <ListItemButton onClick={() => handleChoose(project.project_id)}>
                  <ListItemText
                    primary={project.projject_name}
                    secondary={language === 'ar' ? `( ${project.project_video_count} / 4 )` : `( ${project.project_video_count} / 4 )`}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </Box>
      ) : (
        <Typography textAlign={'center'} color="textSecondary">
          {language === 'ar' ? 'لم يتم العثور على مواضيع' : 'No projects found'}
        </Typography>
      )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseProjectList} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        ) : (
          <div className="studio-container">
          <Box textAlign={'center'} alignItems={'center'} width={'100%'} my={5} mt={5}>
             <img src="../favpng_video-editing-logo.webp" alt='video editer' width='270px' height="270px"/>
          </Box>
        <Dialog open={openLoginDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogContent textAlign={'center'}>
            <p>{language === 'ar' ? 'الرجاء تسجيل الدخول للبدء' : 'Please login to start'}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
        )}
        <Box width={'100%'} textAlign={'center'} alignItems={'center'}>
            <Button aria-label='how to use' color='info' onClick={handleOpenHowToEditerDialog}>
                {language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}
            </Button>
        </Box>

      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}</DialogTitle>
        <DialogContent>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to sticker */}
      <Dialog open={showHowToStickerDialog} onClose={handleCloseHowToStickerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء ستيكر من الفيديو' : 'How to sticker video'}</DialogTitle>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'ماهو الستيكر ؟' : 'What is sticker video ?'}</p>
            <p>{language === 'ar' ? 'هو ملصق بصيغة صورة GIF يستخدم في رسائل الواتس اب أو السوشال ميديا في الكومنت التي تدعم الصور المتحركة' : 'It is a sticker in GIF format used in WhatsApp messages or social media in the comments that support animated images.'}</p>
        </DialogContentText>
        <DialogContent>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_sticker.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToStickerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
    

      <Dialog open={openDeleteVideoBox} onClose={handleCloseDeleteVideoBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الفيديو' : 'Delete video'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الفيديو من القائمة, هل تريد الحذف ؟' : 'This video will deleted form your list, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteVideoBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteVideoBox} onClick={handleDeletVideoBox} color="error">            
            {loadingDeleteVideoBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
};

export default CustumVideoEditer;
