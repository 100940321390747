// src/components/ContactUs.js
// src/components/Navbar.js
import React, { useState } from 'react';
import { Card, CardContent, Box, Container, Typography, Link, Modal, Button } from '@mui/material';
import { useLanguage } from './LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from './ThemeContext';
import useSweetAlert from './SweetAlert';
import CustomTextField from './CustomTextField';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const ContactUs = () => {
    const { language } = useLanguage();
    const { theme } = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const { showAlert } = useSweetAlert();
    const modelBackgroundColor = theme === 'light' ? 'white' : '#090a09'
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      // Send the data to your backend API
      const response = await fetch('https://samrt-loader.com/kydwon/api/contactform', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      });

      if (response.ok) {
          showAlert({
            text: language === 'ar' ? 'تم إرسال رسالتك وسيتم الرد قريبا .' : 'Message sent successfully',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
      } else {
          showAlert({
            text: language === 'ar' ? 'فشل إرسال الرسالة الرجاء المحاولة لاحقا .' : 'Failed to send the message.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
      }
      setFormData({
        name: '',
        email: '',
        message: ''
      });
      setOpenModal(false);
    };

    const handleOpenModal = async() => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };

    return (
        <Container>
          <SEO 
            title={language === 'ar' ? keywordsData.title_contact_us_ar : keywordsData.title_contact_us_en}
            description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
            keywords={keywordsData.keywords.join(', ')}
          />
            <Box width="100%"  my={3}>
                <Link href="./" color="inherit" className="animated-link">
                  <FontAwesomeIcon icon="home" />&nbsp;{language === 'ar' ? 'الرئيسية' : 'Home'}
                </Link>
            </Box>
            <Box mt={4} mx={2} my={20}>
               <Card variant="outlined" sx={{boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)'}} >
                <CardContent>
                   <Typography my={3} textAlign="center" variant="h4">{language === 'ar' ? 'إتصل بنا' : 'Contact us'}</Typography>
                   <Typography my={3} textAlign="center" variant="h6">{language === 'ar' ? 'نحن هنا للإجابة على أي أسئلة أو استفسارات قد تكون لديكم. تواصل معنا وسنرد عليك في أقرب وقت ممكن. في غضون ذلك ، يرجى إلقاء نظرة على شروط الخدمة الخاصة بنا' : 'We are here to answer any questions or inquiries that you may have. Reach out to us and we will respond as soon as possible. In the mean time please have a look at our terms of service.'}</Typography>
                   <Box width="100%"  my={5} textAlign="center">
                      <Link href="./Privacy" color="inherit" className="animated-link">
                        {language === 'ar' ? 'سياسة الخصوصية' : 'Privacy Police'}
                      </Link>
                      &nbsp;&nbsp;
                      <Link href="./Tearms" color="inherit" className="animated-link">
                        {language === 'ar' ? 'شروط الخدمة' : 'Terms of Service'}
                      </Link>
                    </Box>
                    <Box width="100%"  my={5} textAlign="center">
                      <Link href="#" onClick={handleOpenModal} color="inherit" className="animated-link">
                        {language === 'ar' ? 'إرسال رسالة' : 'Send a message'}
                      </Link>
                      <Typography my={3} textAlign="center" variant="h4">{language === 'ar' ? 'أو' : 'Or'}</Typography>
                      <Typography my={3} textAlign="center" variant="h5">{'Mail: samrt.loader@gmail.com'}</Typography>
                    </Box>
                </CardContent>
               </Card>
            </Box>
            {/* Modal to display cntact form */}
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box sx={{ width: '80vw', maxWidth: '800px', height: '450px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
              <div style={{padding:'10px'}}>
                <form onSubmit={handleSubmit}>
                <div>
                    <CustomTextField
                      label={language === 'ar' ? 'الإسم:' : 'Name:'}
                      variant="outlined"
                      value={formData.name}
                      type="text"
                      name="name"
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                   />
                  </div>
                  <div>
                    <CustomTextField
                      label={language === 'ar' ? 'الإيميل:' : 'Email:'}
                      variant="outlined"
                      value={formData.email}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                   />
                  </div>
                  <div>
                    <CustomTextField
                      label={language === 'ar' ? 'الرسالة:' : 'Message:'}
                      variant="outlined"
                      sx={{height:'120px'}}
                      value={formData.message}
                      type="textarea"
                      name="message"
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                   />
                  </div>
                  <Box my={2} textAlign="center">
                    <Button aria-label="send" color="success" variant="contained" type="submit">{language === 'ar' ? 'إرسال' : 'Send'}</Button>
                    <Button aria-label="close" variant="contained" onClick={handleCloseModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
                  </Box>
                </form></div>
              </Box>
            </Modal>
        </Container>
    );
};

export default ContactUs;
