import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';

const RateDialog = ({ onClose }) => {
  const { language } = useLanguage();
  const [rating, setRating] = useState(0);
  const [setedData, setData] = useState(0);

  const handleCheckboxChange = async (value) => {
    setRating(value);
    try {
      const response = await fetch('https://samrt-loader.com/kydwon/api/rate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating: value }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setData(data);
      console.log('Response from API:', data);
      onClose(data); // Close the dialog after successful API call
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleClose = () => {
    onClose(setedData); // Pass the selected rating value to the onClose handler
  };

  return (
    <Dialog open={true}>
      <DialogTitle textAlign='center'>{language === 'ar' ? 'قيمنا' : 'Rate us'}</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign='center'>
          {language === 'ar' ? 'نسعد بتقديم تقييمكم لخدماتنا لتجربة مستخدم أفضل .' : 'We are pleased with your evaluation of our services for a better user experience.'}
        </DialogContentText>
        <Box display="flex" justifyContent="center" mt={2}>
          {[1, 2, 3, 4, 5].map((value) => (
            <FormControlLabel
              key={value}
              control={<Checkbox checked={rating === value} onChange={() => handleCheckboxChange(value)} />}
              label={value}
              labelPlacement="top"
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {language === 'ar' ? 'إغلاق' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateDialog;
