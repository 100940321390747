// src/components/CustomTextField.js
import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    display: 'none', // Hide the label when focused
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'pink', // Customize the underline color when focused
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey', // Customize the border color
    },
    '&:hover fieldset': {
      borderColor: 'black', // Customize the border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'pink', // Customize the border color when focused
    },
  },
});

const CustomTextFieldComponent = ({ label, value, ...props }) => {
  return (
    <CustomTextField
      label={value === '' ? label : ''} // Hide label if value is empty
      value={value}
      InputLabelProps={{
        shrink: false, // Shrink label if there's a value
      }}
      {...props}
    />
  );
};

export default CustomTextFieldComponent;
