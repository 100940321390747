// updateClick.js
import axios from 'axios';

export const updateClick = async (type) => {
  try {
    const response = await axios.post('https://samrt-loader.com/kydwon/api/update_click', {
      type: type,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating click:', error);
    throw error; // Rethrow the error to be handled by the calling function if needed
  }
};
