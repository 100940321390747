// src/components/HomePage.js
import React, { useEffect , useState } from 'react';
import './HomePage.css';
import { Container,Link, Grid, Box, Card, CardContent, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from '@mui/material';
import VideoDownloadForm from './VideoDownloadForm';
import Typewriter from 'typewriter-effect';
import { useLanguage } from './LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import keywordsData from './keywords.json';
import { updateClick } from './updateClick';

const HomePage = () => {
    const siteVer = '19.14.3';
    const [isUpdate, setIsUpdate] = useState(() => {
      const checkIsUpdate = localStorage.getItem('is_updated');
      if (checkIsUpdate === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });
    const { language } = useLanguage();
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
    const facebook = language === 'ar' ? 'فيس بوك' : 'facebook'
    const instgram = language === 'ar' ? 'إنستجرام' : 'Instagram'
    const snapChat = language === 'ar' ? 'سناب شات' : 'Snapchat'
    const tikTok = language === 'ar' ? 'تيك توك' : 'TikTok'
    const kwai = language === 'ar' ? 'كاواي' : 'Kwai'
    const youtube = language === 'ar' ? 'يوتيوب' : 'YouTube'
    const twiter = language === 'ar' ? 'تويتر' : 'twiter'
    const others = language === 'ar' ? 'وغيرها من المواقع' : 'And More ..'
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [showHowToEditerDialog, setShowHowToEditerDialog] = useState(false);
    const [showHowToStickerDialog, setShowHowToStickerDialog] = useState(false);
    const [isReadEditer, setIsReadEditer] = useState(() => {
      // Attempt to load the user from localStorage
      const checkIsRead = localStorage.getItem('is_read_editer');
      if (checkIsRead === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });

    const readUpdate = () => {
      setIsUpdate(false);
      localStorage.setItem('currentUpdateVer',siteVer);
      localStorage.setItem('is_updated','false');
    }

    const handleCloseDialog = async() => {
      await updateClick('update');
      localStorage.setItem('is_updated','true');
      window.location.reload();
    };

    const handleCloseHowToEditerDialog = async() => {
      setShowHowToEditerDialog(false);
      setIsReadEditer(true);
      localStorage.setItem('is_read_editer',"true");
      await updateClick('done_read');
    };

    const handleOpenHowToStickerDialog = () => {
      setShowHowToStickerDialog(true);
    }

    const handleCloseHowToStickerDialog = () => {
      setShowHowToStickerDialog(false);
    }

    const handleOpenNav = (Url) => {
      navigate(`/${Url}`); // Navigate to the ViewRest component
    };

    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    const handleReqStieData = async () => {
      try {
        const token = localStorage.getItem('authToken') || ""
        const response = await fetch('https://samrt-loader.com/kydwon/api/siteInfo' , {
          method: 'GET', // Specify the method
          headers: {
            'X-Authorization':`Bearer ${token}`
          },
        });
        const data = await response.json();
        if (data.success) {
          //setVideoData(response.data.files); // Set videoData state with response data
          setIsMobile(data.is_mobile)
          localStorage.setItem('isMobile',isMobile)
          localStorage.setItem('updateDialogVer', siteVer);
          if(data.is_set_pass){
            localStorage.setItem('is_set_pass', 'true');             
          } else {
            localStorage.setItem('is_set_pass', 'false');
          }
          const updateDialogVer = localStorage.getItem('updateDialogVer');
          if (updateDialogVer !== data.version) {
            setShowUpdateDialog(true);
          }
          
          const currentUpdateVer = localStorage.getItem('currentUpdateVer');
          if (currentUpdateVer !== data.version) {
            if(!isUpdate){
              setIsUpdate(true);
            }
          }

          localStorage.setItem('share_count',data.share_count)
          if(!isReadEditer) {
            if(!isUpdate){
              setShowHowToEditerDialog(true);
            }
          }
        }
      } catch (error) {
      }
    };

    useEffect(() => {
      handleReqStieData();
    });
    
  return (
    <Container>
      <SEO 
        title={language === 'ar' ? keywordsData.title_ar : keywordsData.title_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <div className="homepage-header">
        <h2>{language === 'ar' ? 'تحميل الفيديوهات من ' : 'Download videos from '}
            <Typewriter
                options={{
                   strings: [facebook,instgram,snapChat,tikTok,kwai,youtube,twiter,others],
                   autoStart: true,
                   loop: true,
                }}
            />
        </h2>
        <p>{language === 'ar' ? 'الصق عنوان URL للمنشور أو الوسائط واضغط للتنزيل بدقة عالية' : 'Paste the URL of the post or media and press to download in HD'}</p>
        <VideoDownloadForm isMobile={isMobile} />
      </div>
      <div className="homepage-content">
        <h2>{language === 'ar' ? 'طريقة التحميل' : 'How it works ?'}</h2>
        <ol>
          <li>
            <h3>{language === 'ar' ? 'إبحث عن فيديو' : 'Search for a Video'}</h3>
            <p>
            {language === 'ar' ? 'انسخ عنوان URL للفيديو الذي تريد تنزيله من أي موقع تواصل اجتماعي منصة الوسائط، مثل فيس بوك أو إنستجرام أو سناب شات أو تيك توك أو كاواي أو يوتيوب أو تويتر وغيرها من المواقع التي تدعمها المنصة . ': 'Copy the URL of the video you want to download from any social media platform, such as Snapchat, TikTok, Kawi, Facebook, YouTube, twiter or Instagram and alot of site list that we support .'}
            </p>
          </li>
          <li>
            <h3>{language === 'ar' ? 'إنسخ ولصق رابط القيديو أو المشاركة' : 'Paste the URL'}</h3>
            <p>
              {language === 'ar' ? 'الصق عنوان URL في الحقل المقدم على موقعنا.' : 'Paste the URL into the provided field on our website.'}
            </p>
          </li>
          <li>
            <h3>{language === 'ar' ? 'التحميل' : 'Download!'}</h3>
            <p>
            {language === 'ar' ? 'انقر فوق الزر "تحميل"، وحدد التنسيق والجودة المطلوبة للفيديو الخاص بك.' : 'Click the "Download" button, and select the desired format and quality for your video.'}
            </p>
          </li>
        </ol>
        <Container>
        <Box textAlign="center" width="100%"  my={7}>
        <h2>{language === 'ar' ? 'المكتبة' : 'Gallery'}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./video-camera_7147359.webp"
                alt={language === 'ar' ? 'مكتبة الفيديو' : 'Video Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2 >{language === 'ar' ? 'مكتبة الفيديو' : 'Video Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_videos" variant="contained" color="info" onClick={() => handleOpenNav('./VideoGallery')}>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./camera_7147354.webp"
                alt={language === 'ar' ? 'مكتبة الصور' : 'Photos Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2>{language === 'ar' ? 'مكتبة الصور' : 'Photos Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_photos" variant="contained" color="info" onClick={() => handleOpenNav('./ImagesGallery')}>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./open-book_7147304.webp"
                alt={language === 'ar' ? 'مكتبة الكلمات' : 'Words Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2>{language === 'ar' ? 'مكتبة الكلمات' : 'Words Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_words" variant="contained" color="info" onClick={() => handleOpenNav('./TextsGallery')}>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </Box>
        </Container>
      </div>

      {/* Dialog for is Up Date */}
      <Dialog open={isUpdate} onClose={readUpdate} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'ما الجديد !' : 'Whats New !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <p>{language === 'ar' ? '1- تفعيل روابط threads من منصة إنستجرام.' : '1- Supporting threads video from instagram platform'}</p>
          <p>{language === 'ar' ? '2- إضافة خاصية إنشاء فيديو ستيكر بصيغة GIF للمحرر .' : '2- Adding video to GIF sticker features to editer.'}</p>
          <p>{language === 'ar' ? '3- تحسين وتحديث بعض الخصائص الأخرى.' : '3- Improved and updated other features.'}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={readUpdate} color="primary">
            {language === 'ar' ? 'إغلاق' : 'close'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Up Date */}
      <Dialog open={showUpdateDialog} onClose={handleCloseDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'تحديث جديد !' : 'A new Up date !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language === 'ar' ? 'هناك تحديث جديد للموقع لخدمة أفضل الرجاء تحديث الصفحة من المتصغح ...' : 'There is a new update for the site, For better preference please refresh the page from your explorar ...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {language === 'ar' ? 'تحديث' : 'Refresh'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'أداة المحرر' : 'New feature'}</DialogTitle>
        <DialogContent>
          <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog How to sticker */}
      <Dialog open={showHowToStickerDialog} onClose={handleCloseHowToStickerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء ستيكر من الفيديو' : 'How to sticker video'}</DialogTitle>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'ماهو الستيكر ؟' : 'What is sticker video ?'}</p>
            <p>{language === 'ar' ? 'هو ملصق بصيغة صورة GIF يستخدم في رسائل الواتس اب أو السوشال ميديا في الكومنت التي تدعم الصور المتحركة' : 'It is a sticker in GIF format used in WhatsApp messages or social media in the comments that support animated images.'}</p>
        </DialogContentText>
        <DialogContent>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_sticker.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToStickerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      </Container>
  );
};

export default HomePage;