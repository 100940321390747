import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

function CircularImageWithButton({ src, alt, onUpload }) {
  const fileInputRef = useRef(null);

  const handleEdit = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Call the onUpload function passed as a prop to handle the file upload
      onUpload(file);
    }
  };

  return (
    <div style={styles.container}>
      <img
        src={src}
        alt={alt}
        style={styles.image}
      />
      <Button onClick={handleEdit} style={styles.button}>
         <FontAwesomeIcon icon="fa-solid fa-pen" />
      </Button>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100px',
  },
  image: {
    width: '100px',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  button: {
    position: 'absolute',
    bottom: '10px',
    right: '117px',
    padding: '5px 10px',
    fontSize: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default CircularImageWithButton;
