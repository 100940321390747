import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import { useLoading } from './LoadingContext';
import useSweetAlert from './SweetAlert';
import { useLanguage } from './LanguageContext';
import { jwtDecode } from 'jwt-decode';

const CustomGoogleLogin = ({ lang }) => {
  const { showLoading, hideLoading } = useLoading();
  const { showAlert } = useSweetAlert();
  const { language } = useLanguage();

  const showErrorLogin = (error) => {
    showAlert({
      text: language === 'ar' ? 'حدث خطأ أثناء محاولة التسجيل, الرجاء المحاولة لاحقا !' : 'ُError while try login, Please try agian later !',
      icon: 'error',
      position: language === 'ar' ? 'top-end' : 'top-start'
    });
    hideLoading();
    console.error('Error fetching data:', error);
  };

  const fetchData = async (googleToken) => {
    try {
      showLoading();
      const formData = new FormData();
      formData.append('token_id', googleToken.code);
      //console.error('googleToken:', googleToken);

      // Simulate API call
      const response = await fetch('https://samrt-loader.com/kydwon/api/login', {
        method: 'POST', // Specify the method
        body: formData
      });
      
      const data = await response.json();
      const JWT = data.JWT;
      if(JWT) {
        localStorage.setItem('authToken', JWT);
        localStorage.setItem('isLogin', 'true');
        const decodedToken = jwtDecode(JWT);
        const userid = decodedToken.security.userid;
        const userLevelid = decodedToken.security.userlevelid;
        localStorage.setItem('userid', userid)
        localStorage.setItem('userlevelid', userLevelid)
      }
      //console.log(data);
    } catch (error) {
      showErrorLogin(error)
    } finally {
      const isLogin = localStorage.getItem('isLogin');
      if(isLogin) {
        hideLoading();
        window.location.reload();
      } else {
        showErrorLogin("not login")
      }
    }
  };
  
  const login = useGoogleLogin({
    scope: 'profile email openid',
    flow: 'auth-code',
    onSuccess: (response) => fetchData(response),
    onError: (error) => showErrorLogin(error)
  });

  const buttonText = lang === 'ar' ? 'المتابعة عبر جوجل' : 'Continue with Google';

  return (
    <Button
      fullWidth
      variant="outlined"
      color='error'
      startIcon={<GoogleIcon />}
      onClick={() => login()}
    >
      {buttonText}
    </Button>
  );
};

export default CustomGoogleLogin;
