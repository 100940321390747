// src/components/Privacy.js
import React from 'react';
import { useLanguage } from './LanguageContext';
import {Link ,Box} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';
//import './VideosPage.css';

const Privacy = () => {
  const { language } = useLanguage();
  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );
  
  return (
    <Container>
      <SEO 
        title={language === 'ar' ? keywordsData.title_praivacy_ar : keywordsData.title_praivacy_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
        <Box width="100%"  my={3}>
            <Link href="./" color="inherit" className="animated-link">
               <FontAwesomeIcon icon="home" />&nbsp;{language === 'ar' ? 'الرئيسية' : 'Home'}
            </Link>
        </Box>
      <h2>{language === 'ar' ? 'سياسة الخصوصية لمنصة تطبيقات Smart Loader' : 'Privacy Policy for Smart Loader App Platform'}</h2>
      <p>{language === 'ar' ? `سياسة الخصوصية لمنصة تطبيقات Smart Loader
في Smart Loader App Platform ، الذي يمكن الوصول إليه من https://samrt-loader.com ، تتمثل إحدى أولوياتنا الرئيسية في خصوصية زوارنا. يحتوي مستند سياسة الخصوصية هذا على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة Smart Loader App Platform وكيفية استخدامنا لها.

إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا.

تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة لزوار موقعنا الإلكتروني فيما يتعلق بالمعلومات التي شاركوها و / أو يجمعونها في Smart Loader App Platform. لا تنطبق هذه السياسة على أي معلومات يتم جمعها في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع.` : 'At Smart Loader App Platform, accessible from https://samrt-loader.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Smart Loader App Platform and how we use it.If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Smart Loader App Platform. This policy is not applicable to any information collected offline or via channels other than this website.'}</p>
<h2>{language === 'ar' ? `الموافقة` : `Consent`}</h2>
<p>{language === 'ar' ? `باستخدام موقعنا ، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا وتوافق على شروطها.` : `By using our website, you hereby consent to our Privacy Policy and agree to its terms.`}</p>

<h2>{language === 'ar' ? `المعلومات التي نجمعها` : `Information we collect`}</h2>
<p>{language === 'ar' ? `سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها ، وأسباب مطالبتك بتقديمها ، في الوقت الذي نطلب منك فيه تقديم معلوماتك الشخصية.

إذا اتصلت بنا مباشرة ، فقد نتلقى معلومات إضافية عنك مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك ومحتويات الرسالة و / أو المرفقات التي قد ترسلها إلينا وأي معلومات أخرى قد تختار تقديمها.

عند التسجيل للحصول على حساب ، قد نطلب معلومات الاتصال الخاصة بك ، بما في ذلك عناصر مثل الاسم واسم الشركة والعنوان وعنوان البريد الإلكتروني ورقم الهاتف.` : `The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.`}</p>

<h2>{language === 'ar' ? `كيف نستخدم معلوماتك` : `How we use your information`}</h2>
<p>{language === 'ar' ? `نستخدم المعلومات التي نجمعها بطرق مختلفة ، بما في ذلك:

توفير وتشغيل وصيانة webste الخاص بنا
تحسين وتخصيص وتوسيع webste الخاص بنا
فهم وتحليل كيفية استخدامك لموقع الويب الخاص بنا
تطوير منتجات وخدمات وميزات ووظائف جديدة
التواصل معك ، إما بشكل مباشر أو من خلال أحد شركائنا ، بما في ذلك لخدمة العملاء ، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة بموقع الويب ، ولأغراض تسويقية وترويجية
أرسل لك رسائل البريد الإلكتروني
البحث عن ومنع الاحتيال` : `How we use your information
We use the information we collect in various ways, including to:

Provide, operate, and maintain our webste
Improve, personalize, and expand our webste
Understand and analyze how you use our webste
Develop new products, services, features, and functionality
Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes
Send you emails
Find and prevent fraud`}</p>

<h2>{language === 'ar' ? `ملفات الدخول` : `Log Files`}</h2>
<p>{language === 'ar' ? `تتبع Smart Loader App Platform إجراءً قياسيًا لاستخدام ملفات السجل. تسجل هذه الملفات الزوار عندما يزورون مواقع الويب. تقوم جميع شركات الاستضافة بذلك وجزء من تحليلات خدمات الاستضافة. تتضمن المعلومات التي يتم جمعها بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع المستعرض وموفر خدمة الإنترنت (ISP) وختم التاريخ والوقت وصفحات الإحالة / الخروج وربما عدد النقرات. هذه ليست مرتبطة بأي معلومات لتحديد الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات الديموغرافية. تم إنشاء سياسة الخصوصية الخاصة بنا بمساعدة منشئ سياسة الخصوصية ومنشئ إخلاء المسؤولية.` : `Smart Loader App Platform follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Disclaimer Generator.`}</p>

<h2>{language === 'ar' ? `ملفات تعريف الارتباط وإشارات الويب` : `Cookies and Web Beacons`}</h2>
<p>{language === 'ar' ? `مثل أي موقع ويب آخر ، يستخدم Smart Loader App Platform "ملفات تعريف الارتباط". تُستخدم ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك تفضيلات الزوار والصفحات الموجودة على موقع الويب التي قام الزائر بالوصول إليها أو زيارتها. تُستخدم المعلومات لتحسين تجربة المستخدمين من خلال تخصيص محتوى صفحة الويب الخاصة بنا بناءً على نوع متصفح الزوار و / أو معلومات أخرى.

لمزيد من المعلومات العامة حول ملفات تعريف الارتباط ، يرجى قراءة "ما المقصود بملفات تعريف الارتباط".` : `Like any other website, Smart Loader App Platform uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.

For more general information on cookies, please read "What Are Cookies".`}</p>

<h2>{language === 'ar' ? `سياسات خصوصية الطرف الثالث` : `Third Party Privacy Policies`}</h2>
<p>{language === 'ar' ? `منصة تطبيقات Smart Loader لا تستخدم أي مصدر مفتوح` : `Smart Loader App Platform's Not Using any Open source`}</p>

<h2>{language === 'ar' ? `حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية)` : `CCPA Privacy Rights (Do Not Sell My Personal Information)`}</h2>
<p>{language === 'ar' ? `بموجب قانون CCPA، من بين حقوق أخرى، يحق للمستهلكين في كاليفورنيا:

اطلب من الشركة التي تجمع البيانات الشخصية للمستهلك أن تكشف عن فئات وأجزاء محددة من البيانات الشخصية التي جمعتها الشركة عن المستهلكين.

اطلب من الشركة حذف أي بيانات شخصية عن المستهلك قامت الشركة بجمعها.

اطلب من الشركة التي تبيع البيانات الشخصية للمستهلك، عدم بيع البيانات الشخصية للمستهلك.

إذا قمت بتقديم طلب، لدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا.` : `Under the CCPA, among other rights, California consumers have the right to:

Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.

Request that a business delete any personal data about the consumer that a business has collected.

Request that a business that sells a consumer's personal data, not sell the consumer's personal data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.`}</p>

<h2>{language === 'ar' ? `حقوق حماية البيانات بموجب القانون العام لحماية البيانات (GDPR).` : `GDPR Data Protection Rights`}</h2>
<p>{language === 'ar' ? `نود التأكد من أنك على دراية كاملة بجميع حقوق حماية البيانات الخاصة بك. يحق لكل مستخدم ما يلي:

حق الوصول – لديك الحق في طلب نسخ من بياناتك الشخصية. قد نفرض عليك رسومًا بسيطة مقابل هذه الخدمة.

الحق في التصحيح – يحق لك أن تطلب تصحيح أي معلومات تعتقد أنها غير دقيقة. لديك أيضًا الحق في طلب استكمال المعلومات التي تعتقد أنها غير كاملة.

الحق في المحو – يحق لك أن تطلب مسح بياناتك الشخصية، في ظل ظروف معينة.

الحق في تقييد المعالجة – يحق لك أن تطلب تقييد معالجة بياناتك الشخصية، في ظل ظروف معينة.

الحق في الاعتراض على المعالجة – لديك الحق في الاعتراض على معالجتنا لبياناتك الشخصية، في ظل ظروف معينة.

الحق في إمكانية نقل البيانات – لديك الحق في طلب نقل البيانات التي جمعناها إلى مؤسسة أخرى، أو إليك مباشرة، في ظل ظروف معينة.

إذا قمت بتقديم طلب، لدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا.` : `We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.`}</p>

<h2>{language === 'ar' ? `معلومات الاطفال` : `Children's Information`}</h2>
<p>{language === 'ar' ? `جزء آخر من أولويتنا هو إضافة الحماية للأطفال أثناء استخدام الإنترنت. نحن نشجع الآباء والأوصياء على مراقبة نشاطهم عبر الإنترنت والمشاركة فيه و/أو مراقبته وتوجيهه.

لا تقوم منصة تطبيقات Smart Loader بجمع أي معلومات تعريف شخصية من الأطفال الذين تقل أعمارهم عن 13 عامًا. إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على موقعنا، فإننا نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى جهدنا لإزالة هذه المعلومات من سجلاتنا على الفور.` : `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

Smart Loader App Platform does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`}</p>
    </Container>
  );
};

export default Privacy;