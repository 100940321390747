import React from 'react';
import { Dialog, CircularProgress, DialogContent } from '@mui/material';
import { useLoading } from './LoadingContext';

const LoadingDialog = () => {
  const { loading } = useLoading();

  return (
    <Dialog open={loading}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
