// src/components/ImagesSectionsBar.js

import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from './ThemeContext';

const ImagesSectionsBar = ({ sections, onSectionClick }) => {
    const [selectedSection, setSelectedSection] = useState(null);
    const { theme } = useTheme();
    //const buttonColor = theme === 'light' ? '#d9abb0' : '#fff'
    const buttonBackgroundColor = theme === 'light' ? '#dfc7b2' : '#4d4844'
    const boxBackgroundColor = theme === 'light' ? '#e3dfde' : '#080406'
    const textColor = theme === 'light' ? '#870749' : '#fff'
    const scrollColor = theme === 'light' ? '#f0f0f0' : '#0e0000'


    const handleSectionClick = (sectionId) => {
      if(selectedSection === sectionId){
        return;
      }
      if(sectionId === ""){
        setSelectedSection(0);
      } else {
        setSelectedSection(sectionId);
      }
      onSectionClick(sectionId);
    };
    
  return (
    <Box sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '16px',
        backgroundColor: [boxBackgroundColor],
        position: 'sticky',
        top: -0.5,
        zIndex: 1,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
        '&::-webkit-scrollbar': { // Change scrollbar styles
          width: '8px',
          height: '8px',
          backgroundColor: [scrollColor],
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ccc',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: [scrollColor],
        },
    }}
    width="100%">
      {sections.map((section) => (
        <Box key={section.id} sx={{ display: 'inline-block', marginRight: 4 }}>
            {section.id === 0 ? (
                <Button aria-label="sec" color="inherit"  sx={{color: 'red', backgroundColor: selectedSection === section.id ? [buttonBackgroundColor] : 'inherit'}} variant="button" href="#" onClick={() => handleSectionClick("")}>
                    {section.section_name}
                </Button>
            ) : (  
                <Button aria-label={"sec-" + section.id} color="inherit" sx={{color: [textColor], backgroundColor: selectedSection === section.id ? [buttonBackgroundColor] : 'inherit'}} variant="button" href="#" onClick={() => handleSectionClick(section.id)}>
                   {section.section_name}
                </Button>
            )}
        </Box>
      ))}
    </Box>
  );
};

export default ImagesSectionsBar;