// src/components/Tearms.js
import React from 'react';
import { useLanguage } from './LanguageContext';
//import './Tearms.css';
import {Link ,Box} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const Tearms = () => {
  const { language } = useLanguage();
  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );

  return (
    <Container>
      <SEO 
        title={language === 'ar' ? keywordsData.title_tearms_ar : keywordsData.title_tearms_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
        <Box width="100%"  my={3}>
            <Link href="./" color="inherit" className="animated-link">
                <FontAwesomeIcon icon="home" />&nbsp;{language === 'ar' ? 'الرئيسية' : 'Home'}
            </Link>
        </Box>
       <h2>{language === 'ar' ? `شروط الإستخدام` :`Terms of Servers`}</h2>
       <p>{language === 'ar' ? `المبينة أدناه هي شروط وأحكام استخدامك لموقعنا والوصول إليه. يرجى قراءتها بعناية ، لأنها تفرض عليك مسؤوليات فيما يتعلق باستخدامك لبرنامج Smart Load Downloader.

من خلال الوصول إلى موقع الويب هذا ، فإنك توافق على الالتزام بشروط وأحكام استخدام موقع الويب هذه ، وجميع القوانين واللوائح المعمول بها ، وتوافق على أنك مسؤول عن الامتثال لأي قوانين محلية معمول بها. إذا كنت لا توافق على أي من هذه الشروط ، فيحظر عليك استخدام أو الوصول إلى هذا الموقع. المواد الواردة في هذا الموقع محمية بموجب قانون حقوق النشر والعلامات التجارية المعمول به.` : `Set out below are the terms and conditions of your use of, and access to, our website. Please read them carefully, as they impose responsibilities on you in respect of your use of Smart Load Downloader.

By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.`}</p>

       <h2>{language === 'ar' ? `ترخيص الاستخدام` :`Use License`}</h2>
       <p>{language === 'ar' ? `يُمنح الإذن لتنزيل نسخة واحدة من المواد (المعلومات أو البرامج) مؤقتًا على موقع Smart Loader من أجل العرض الشخصي غير التجاري العابر فقط. هذا هو منح الترخيص ، وليس نقل الملكية ، وبموجب هذا الترخيص لا يجوز لك:

تعديل أو نسخ المواد ؛
استخدام المواد لأي غرض تجاري ، أو لأي عرض عام (تجاري أو غير تجاري) ؛
إزالة أي حقوق نشر أو تدوينات ملكية أخرى من المواد ؛ أو
نقل المواد إلى شخص آخر أو "نسخ" المواد الموجودة على أي خادم آخر.
نحن ملتزمون بإجراء أعمالنا وفقًا لهذه المبادئ من أجل ضمان حماية سرية المعلومات الشخصية والحفاظ عليها.` : `Permission is granted to temporarily download one copy of the materials (information or software) on Smart Loader website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:

Modify or copy the materials;
Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
Remove any copyright or other proprietary notations from the materials; or
Transfer the materials to another person or "mirror" the materials on any other server.
We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.`}</p>

       <h2>{language === 'ar' ? `تنصل` :`Disclaimer`}</h2>
       <p>{language === 'ar' ? `يتم توفير المواد الموجودة على موقع Smart Loader "كما هي". لا تقدم Smart Loader أي ضمانات ، صريحة أو ضمنية ، وتخلي مسؤوليتها وتبطل بموجبها جميع الضمانات الأخرى ، بما في ذلك على سبيل المثال لا الحصر ، الضمانات الضمنية أو شروط القابلية للتسويق أو الملاءمة لغرض معين أو عدم التعدي على الملكية الفكرية أو أي انتهاك آخر للحقوق. علاوة على ذلك ، لا تضمن Smart Loader أو تقدم أي تعهدات تتعلق بالدقة أو النتائج المحتملة أو موثوقية استخدام المواد الموجودة على موقع الويب الخاص بها أو المتعلقة بهذه المواد أو على أي مواقع مرتبطة بهذا الموقع.` : `The materials on Smart Loader website are provided "as is". Smart Loader makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Smart Loader does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.`}</p>

       <h2>{language === 'ar' ? `التقييد` :`Limitations`}</h2>
       <p>{language === 'ar' ? `لا تتحمل Smart Loader أو مورديها بأي حال من الأحوال المسؤولية عن أي أضرار (بما في ذلك ، على سبيل المثال لا الحصر ، الأضرار الناجمة عن فقدان البيانات أو الأرباح ، أو بسبب انقطاع الأعمال) الناشئة عن استخدام أو عدم القدرة على استخدام المواد على Smart Loader Internet الموقع ، حتى إذا تم إخطار Smart Loader أو الممثل المعتمد لـ Smart Loader شفهيًا أو كتابيًا بإمكانية حدوث مثل هذا الضرر. نظرًا لأن بعض الولايات القضائية لا تسمح بفرض قيود على الضمانات الضمنية أو قيود المسؤولية عن الأضرار التبعية أو العرضية ، فقد لا تنطبق هذه القيود عليك.` : `In no event shall Smart Loader or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Smart Loader Internet site, even if Smart Loader or a Smart Loader authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.`}</p>

       <h2>{language === 'ar' ? `المراجعات و Errata` :`Revisions and Errata`}</h2>
       <p>{language === 'ar' ? `يمكن أن تشتمل المواد التي تظهر على موقع Smart Loader على أخطاء فنية أو مطبعية أو فوتوغرافية. لا تضمن Smart Loader.com أن أيًا من المواد الموجودة على موقع الويب الخاص بها دقيقة أو كاملة أو حديثة. قد تقوم Smart Loader بإجراء تغييرات على المواد الموجودة على موقع الويب الخاص بها في أي وقت دون إشعار مسبق. ومع ذلك ، لا تقدم Smart Loader أي التزام بتحديث المواد.` : `The materials appearing on Smart Loader website could include technical, typographical, or photographic errors. Smart Loader.com does not warrant that any of the materials on its web site are accurate, complete, or current. Smart Loader may make changes to the materials contained on its web site at any time without notice. Smart Loader does not, however, make any commitment to update the materials.`}</p>

       <h2>{language === 'ar' ? `الروابط` :`Links`}</h2>
       <p>{language === 'ar' ? `لم تقم Smart Loader بمراجعة جميع المواقع المرتبطة بموقعها على الإنترنت وليست مسؤولة عن محتويات أي موقع مرتبط من هذا القبيل. إن تضمين أي رابط لا يعني موافقة Smart Loader على الموقع. استخدام أي موقع ويب مرتبط يكون على مسؤولية المستخدم الخاصة.` : `Smart Loader has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Smart Loader of the site. Use of any such linked web site is at the user's own risk.`}</p>

       <h2>{language === 'ar' ? `صندوق المشاركات` :`Share box`}</h2>
       <p>{language === 'ar' ? `نحن نسمح فقط بنوع الفيديو (shorts, reels, watch) أي مقاطع فيديو خاصة أو جنسية أو تحرض على العنف والكراهية أو تخص أماكن حكومية أو خاصة سيتم حذفها على الفور حفاظا على حقوق ملكية الطرف لأخر.` :`We only allow video type (shorts, reels, watch) any video that is private or marital or incites violence and hatred or belongs to government or private places to delete it immediately to preserve the property rights of the other party.`}</p>

       <h2>{language === 'ar' ? `محرر الفيديو` :`Video editer`}</h2>
       <p>{language === 'ar' ? `كامل مسؤولية الفيديو المنتج عن طريق أداة محرر الفيديو الخاصة بنا تقع على عاتق مسؤولية المستخدم ونحن نتنصل من أي مسائلة قانونية قد تنتج عن إساءة إستخدام الأداة وسيتم إتخاذ الإجراء الازم في حال مخالفة أو إساءة إستخدام الأداة` :`The full responsibility for the video produced by our video editor tool lies with the user and we disclaim any legal liability that may result from misuse of the tool and the necessary action will be taken in the event of a violation or misuse of the tool`}</p>

       <h2>{language === 'ar' ? `حفظ البيانات` :`Data store`}</h2>
       <p>{language === 'ar' ? `نقوم بجمع جميع البيانات المضافة إلى موقعنا من حسابك المسجل، ولكننا لا نحتفظ بهذه البيانات على خادمنا لأكثر من 12 ساعة، وقد تتم إعادة تعيين بياناتك أو تجديدها تلقائيًا حتى بيانات الحساب المسجل وقائمة المحرر وصندوق المشاركات.` :`We collect all data intered to our site from your rigistred account, But we don not keep this data on our server more then 12 our your data maybe reset or renew atumaticlly even the rigistred account data and video editer list and the share box.`}</p>

       <h2>{language === 'ar' ? `تعديلات شروط استخدام الموقع` :`Site Terms of Use Modifications`}</h2>
       <p>{language === 'ar' ? `قد تقوم Smart Loader بمراجعة شروط الاستخدام لموقعها على الويب في أي وقت دون إشعار مسبق. باستخدام موقع الويب هذا ، فإنك توافق على الالتزام بالإصدار الحالي آنذاك لشروط وأحكام الاستخدام هذه. الشروط والأحكام العامة المطبقة على استخدام موقع ويب.` : `Smart Loader may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use. General Terms and Conditions applicable to Use of a Web Site.`}</p>
    </Container>
  );
};

export default Tearms;